import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";

import { FilterMatchMode } from "primereact/api";
import Php from "../../Backend/Php";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import PositionCard from "./PositionCard";
import TableHeader from "./TableHeader/TableHeader";

import { connect } from "react-redux";
import UpdateTrade from "../Common/Update";
import VolumeCard from "./Card/VolumeCard";
import Filter from "./Filter/Filter";
import ParkingModal from "./Modal/ParkingModal";
import jsPDF from "jspdf";
import NumberFormatIn from "../Common/NumberFormatIn";

const php = new Php();
const height = window.innerHeight;
const totalHeight = (height / 100) * 95;

var tableRef = React.createRef();
var tableRef2 = React.createRef();

var filterTypeUser = "";
var filterDataUser = "";
var filterArrayUser = "";

var filterTypeScript = "";
var filterDataScript = "";
var filterArrayScript = "";

function Position(props) {
  const [products, setProducts] = useState([]);
  const [products2, setProducts2] = useState([]);
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [dropdown, setDropdown] = useState("");
  const dt = useRef(null);
  const ref = useRef();

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filterSymbol, setFilterSymbol] = React.useState([]);
  const [filterUser, setFilterUser] = React.useState([]);

  const [filterSymbolApi, setFilterSymbolApi] = React.useState([]);
  const [filterUserApi, setFilterUserApi] = React.useState([]);

  const [selectedUser, setSelectedUser] = React.useState({});
  const [selectedSymbol, setSelectedSymbol] = React.useState({});

  useEffect(() => {
    load_positions();
  }, []);

  const handleGeneratePDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    const tableElement = tableRef.current;

    // Create an array to store the table data
    const tableData = [];

    // Iterate through the child elements of the table
    for (let i = 0; i < tableElement.children.length; i++) {
      const rowElement = tableElement.children[i];
      const rowData = [];

      // Iterate through the cells (divs) of each row
      for (let j = 0; j < rowElement.children.length - 1; j++) {
        const cellElement = rowElement.children[j];
        rowData.push(cellElement.innerText);
      }

      // Add the row data to the table data array
      tableData.push(rowData);
    }

    const columdata = tableData[0];
    const tablenewdata = tableData.slice(1);
    doc.setFont("Arial", "bold");
    doc.autoTable({
      head: [columdata], // Replace with your actual column headers
      body: tablenewdata,
    });

    const tableElement2 = tableRef2.current;

    // Create an array to store the second table data
    const tableData2 = [];

    // Iterate through the child elements of the second table
    for (let i = 0; i < tableElement2.children.length - 2; i++) {
      const cellElement = tableElement2.children[i];
      tableData2.push([cellElement.innerText]);
    }

    const transposedTableData2 = tableData2[0].map((col, i) =>
      tableData2.map((row) => row[i])
    );
    // Add spacing between tables
    // doc.ln(10);

    // Add the second table to the PDF using autotable
    doc.autoTable({
      body: transposedTableData2,
    });

    // Save or display the PDF

    // Save or display the PDF
    doc.save("table.pdf");
  };

  const load_positions = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        b_id: localStorage.getItem("brokersId"),
      };
      php.load_positions(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          props.clear_pf();
          const uniqueSymbols = new Set();
          const uniqueUsers = new Set();
          const array = r.positions;

          array.forEach((item) => {
            const symbolKey = JSON.stringify({
              symbol: item.symbol,
              script_id: item.script_id,
            });
            const userKey = JSON.stringify({
              username: item.username,
              id: item.users_id,
            });

            uniqueSymbols.add(symbolKey);
            uniqueUsers.add(userKey);
          });

          // To get the unique objects back, you can convert them back from JSON
          const uniqueSymbolObjects = Array.from(uniqueSymbols).map((key) =>
            JSON.parse(key)
          );
          const uniqueUserObjects = Array.from(uniqueUsers).map((key) =>
            JSON.parse(key)
          );

          setFilterSymbol(Array.from(uniqueSymbolObjects));
          setFilterSymbolApi(Array.from(uniqueSymbolObjects));

          setFilterUser(Array.from(uniqueUserObjects));
          setFilterUserApi(Array.from(uniqueUserObjects));

          if (filterTypeUser == "customer") {
            filterUsers(filterDataUser, r.positions, filterArrayUser);
          } else if (filterTypeScript == "script") {
            filterScripts(filterDataScript, r.positions, filterArrayScript);
          } else {
            filterTypeUser = "";
            filterDataUser = "";
            filterArrayUser = "";

            filterTypeScript = "";
            filterDataScript = "";
            filterArrayScript = "";

            props.clear_pf();
            setProducts(r.positions);
            ref.current.calculatevolume(r.positions);
          }
          setProducts2(r.positions);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const filterbar = (
    <div
      ref={tableRef2}
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
      }}
    >
      <VolumeCard ref={ref} />
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>M2M</div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 16,
            color:
              parseFloat(
                parseFloat(props.total) + parseFloat(props.brokerages)
              ).toFixed(2) > 0
                ? NewTheme.greencolor
                : NewTheme.redcolor,
          }}
        >
          <NumberFormatIn
            value={parseFloat(
              parseFloat(props.total) + parseFloat(props.brokerages)
            ).toFixed(2)}
          />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Brokerage</div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 16,
            color: NewTheme.greencolor,
          }}
        >
          <NumberFormatIn value={parseFloat(props.brokerages).toFixed(2)} />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Profit/Loss</div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 16,
            color:
              parseFloat(props.total).toFixed(2) > 0
                ? NewTheme.greencolor
                : NewTheme.redcolor,
          }}
        >
          <NumberFormatIn value={parseFloat(props.total).toFixed(2)} />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 60,
        }}
      >
        <Button
          type="button"
          label="Parking"
          severity="help"
          style={{
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={() => show()}
          data-pr-tooltip="Parking"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="PDF"
          severity="warning"
          style={{
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={handleGeneratePDF}
          data-pr-tooltip="PDF"
        />
        {/* <Button
          type="button"
          icon="pi pi-file-pdf"
          severity="warning"
          style={{ height: 25, width: 25 }}
          
          data-pr-tooltip="PDF"
        /> */}
      </div>
    </div>
  );

  const filterUsers = (a, data, selectedUserData) => {
    if (selectedUserData == undefined || selectedUserData == "") {
      filterTypeUser = "";
      filterDataUser = "";
      filterArrayUser = "";
      ref.current.calculatevolume(products2);

      if (filterTypeScript == "script") {
        filterScripts(filterDataScript, products2, filterArrayScript);
      } else {
        setProducts(products2);
      }
      setSelectedUser({});
    } else {
      filterTypeUser = "";
      filterDataUser = "";
      filterArrayUser = "";
      props.clear_pf();

      let positionData = [];
      if (filterTypeScript == "script") {
        positionData = data.filter(
          (x) => x.users_id == a && x.script_id == filterDataScript
        );
      } else {
        positionData = data.filter((x) => x.users_id == a);
      }

      setProducts(positionData);
      setSelectedUser(selectedUserData);
      ref.current.calculatevolume(positionData);

      filterTypeUser = "customer";
      filterDataUser = a;
      filterArrayUser = selectedUserData;
    }
  };

  const filterScripts = (a, data, selectedUserData) => {
    if (selectedUserData == undefined || selectedUserData == "") {
      filterTypeScript = "";
      filterDataScript = "";
      filterArrayScript = "";
      ref.current.calculatevolume(products2);
      setSelectedSymbol({});

      if (filterTypeUser == "customer") {
        filterUsers(filterDataUser, products2, filterArrayUser);
      } else {
        setProducts(products2);
      }
    } else {
      filterTypeScript = "";
      filterDataScript = "";
      filterArrayScript = "";
      props.clear_pf();

      let positionData = [];
      if (filterTypeUser == "customer") {
        positionData = data.filter(
          (x) => x.users_id == filterDataUser && x.script_id == a
        );
      } else {
        positionData = data.filter((x) => x.script_id == a);
      }

      setProducts(positionData);
      setSelectedSymbol(selectedUserData);
      ref.current.calculatevolume(positionData);

      filterTypeScript = "script";
      filterDataScript = a;
      filterArrayScript = selectedUserData;
    }
  };

  const filterClear = () => {
    props.clear_pf();
    setProducts(products2);
    setSelectedUser({});
    setSelectedSymbol({});
    filterTypeUser = "";
    filterDataUser = "";
    filterArrayUser = "";
    filterTypeScript = "";
    filterDataScript = "";
    filterArrayScript = "";
    ref.current.calculatevolume(products2);
  };

  const searchBar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        paddingRight: 20,
      }}
    >
      <Filter
        userData={filterUser}
        scriptData={filterSymbol}
        selectedUser={selectedUser}
        selectedSymbol={selectedSymbol}
        filterUsers={(selectedUserData) =>
          filterUsers(selectedUserData?.id, products2, selectedUserData)
        }
        filterScripts={(selectedSymbolData) =>
          filterScripts(
            selectedSymbolData?.script_id,
            products2,
            selectedSymbolData
          )
        }
      />
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          icon="pi pi-times"
          severity="danger"
          style={{ height: 25, width: 25 }}
          onClick={() => filterClear()}
          data-pr-tooltip="PDF"
        />
      </div>
    </div>
  );

  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setPosition("top");
    setVisible(true);
  };

  return (
    <>
      <div className="card">
        <UpdateTrade update={() => load_positions()} />
        <TopbarMost background={"#ffffff"} cmp={filterbar} name={"Positions"} />

        <TopbarMost
          height={45}
          background={"#ffffff"}
          noSidebar
          cmp={searchBar}
        />
        <div
          className="scroll-css"
          ref={tableRef}
          style={{ overflowY: "scroll", height: totalHeight - 90 }}
        >
          <TableHeader />
          {products.length > 0
            ? products.map((i, t) => {
                return (
                  <PositionCard
                    item={i}
                    key={i.id}
                    load_position={() => load_positions()}
                    total={products.length}
                  />
                );
              })
            : null}
        </div>
      </div>
      {visible && (
        <ParkingModal
          setVisible={() => setVisible(false)}
          position={position}
          visible={visible}
        />
      )}
    </>
  );
}

const MapStateToDispatch = (dispatch) => {
  return {
    clear_pf: () => {
      dispatch({
        type: "CLEAR_PF",
      });
    },
  };
};
const mapstatetoProps = (state) => {
  return {
    total: state.total,
    brokerages: state.brokerages,
    theme: state.theme,
  };
};

export default connect(mapstatetoProps, MapStateToDispatch)(Position);
