import React, { useState } from "react";
import styled from "styled-components";
import Php from "../../../Backend/Php";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import LiveSummaryModal from "./Modal/LiveSummaryModal";
import {
  BlackText,
  BlueText,
  BoldText,
  ButtonGrid,
  CreateClientButton,
  FlexContainer,
  FlexItem,
  GrayText,
  GreenText,
  Wrapper,
} from "./Styled";

const php = new Php();

const Container = styled.div`
  display: flex;
  gap: 10px;
`;

const EditButton = styled.button`
  padding: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export default function Overview(props) {
  const [loading, setLoading] = React.useState(false);
  const [buttonsData, setButtonsData] = React.useState([]);
  const [clientData, setClientData] = React.useState([]);
  const { addNotification } = useNotification();
  const [timeDiff, setTimeDiff] = useState(0);

  React.useEffect(() => {
    load_users_overview();
  }, [props.data.id]);

  const load_users_overview = () => {
    if (!loading) {
      setLoading(true);
      let data2 = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        b_id: localStorage.getItem("brokersId"),
        c_id: props.data.id,
      };

      php.load_users_overview(data2).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          setClientData(r.users_overview[0]);
          setButtonsData(r.buttons_data);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const [visibleLiveSummaryModal, setVisibleLiveSummaryModal] = useState(false);
  const [position, setPosition] = useState("top");

  const showLiveSummaryModal = (position) => {
    setPosition("top");
    setVisibleLiveSummaryModal(true);
  };

  return (
    <>
      <Wrapper>
        <FlexContainer>
          <FlexItem>
            <GrayText>Client Name</GrayText>
            <BoldText>
              <span style={{ color: "gray" }}>
                {clientData?.alias == null ? "" : clientData?.alias + " | "}
              </span>
              {clientData?.name}
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Client Username</GrayText>
            <BlueText>{clientData?.username}</BlueText>
          </FlexItem>
          <FlexItem>
            <GrayText>Weekly Profit Loss</GrayText>
            <GreenText
              style={{
                color:
                  clientData?.weekly_pl >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={clientData?.weekly_pl} />
            </GreenText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>Balance</GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <BlackText>
                <NumberFormatIn value={clientData?.deposit} />
              </BlackText>
            </div>
          </FlexItem>
          <FlexItem>
            <GrayText>Total Deposit</GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <BoldText>
                <NumberFormatIn
                  value={parseFloat(clientData?.total_limit).toFixed(0)}
                />
              </BoldText>
            </div>
          </FlexItem>

          <FlexItem>
            <GrayText>Broker</GrayText>
            <BoldText>
              {clientData.broker_id > 0 ? clientData.broker_username : "----"}
            </BoldText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>Multiplier</GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <BoldText>Fut : {clientData?.fut_multiplier}x </BoldText>
                <BoldText> Mcx : {clientData?.mcx_multiplier}x </BoldText>
                <BoldText> Option : {clientData?.fo_multiplier}x</BoldText>
              </div>
            </div>
          </FlexItem>
          <FlexItem>
            <GrayText>Trade Limit</GrayText>
            <BoldText>
              <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <BoldText>
                    Fut :{" "}
                    <NumberFormatIn
                      value={
                        parseFloat(clientData?.total_limit).toFixed(0) *
                        parseFloat(clientData?.fut_multiplier).toFixed(0)
                      }
                    />
                  </BoldText>
                  <BoldText>
                    {" "}
                    Mcx :{" "}
                    <NumberFormatIn
                      value={
                        parseFloat(clientData?.total_limit).toFixed(0) *
                        parseFloat(clientData?.mcx_multiplier).toFixed(0)
                      }
                    />
                  </BoldText>
                  <BoldText>
                    {" "}
                    Option :{" "}
                    <NumberFormatIn
                      value={
                        parseFloat(clientData?.total_limit).toFixed(0) *
                        parseFloat(clientData?.fo_multiplier).toFixed(0)
                      }
                    />
                  </BoldText>
                </div>
              </div>
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>
              Sharing:{" "}
              <span style={{ color: "black" }}>
                {clientData.broker_id > 0 ? clientData.sharing : "0"}%
              </span>
            </GrayText>
            <BoldText>
              {clientData.broker_id > 0
                ? "Fut : " +
                  clientData.fut_percent +
                  "% | Mcx : " +
                  clientData.mcx_percent +
                  "% | Option : " +
                  clientData.fo_percent +
                  "%"
                : "----"}
            </BoldText>
          </FlexItem>
        </FlexContainer>
      </Wrapper>

      <ButtonGrid>
        <CreateClientButton
          active={true}
          color={NewTheme.MainColor}
          onClick={() => showLiveSummaryModal()}
        >
          {"Live Summary"}
        </CreateClientButton>
        <CreateClientButton
          active={clientData?.status == 1 ? true : false}
          color={"#22c55e"}
        >
          {"ACTIVE"}
        </CreateClientButton>
        <CreateClientButton active={true} color={"#22c55e"}>
          {"LOCK " +
            (clientData?.block_minute > 0
              ? "(" + clientData?.block_minute + "m)"
              : "")}
        </CreateClientButton>
        <CreateClientButton active={true} color={"#22c55e"}>
          {"Boundary " +
            (clientData?.fatak != "" ? "(" + clientData?.fatak + ")" : "")}
        </CreateClientButton>
        {buttonsData.map((button, index) => (
          <CreateClientButton
            key={index}
            active={button.active}
            color={button.color}
          >
            {button.text}
          </CreateClientButton>
        ))}
      </ButtonGrid>
      {visibleLiveSummaryModal ? (
        <LiveSummaryModal
          key={clientData?.id}
          usersId={clientData?.id}
          setVisible={() => setVisibleLiveSummaryModal(false)}
          position={position}
          visible={visibleLiveSummaryModal}
          //
          fut_multiplier={clientData?.fut_multiplier}
          mcx_multiplier={clientData?.mcx_multiplier}
          fo_multiplier={clientData?.fo_multiplier}
          //
          deposit={clientData?.deposit}
          blocked_limit={clientData?.blocked_limit}
          weekly_pl={clientData?.weekly_pl}
          clientUsername={clientData?.username}
        />
      ) : null}
    </>
  );
}
