import React, { useState } from "react";
import Php from "../../Backend/Php";
import { useNotification } from "../Notification/NotificationContext";
import TopbarMost from "../Common/TopbarMost";
import UpdateTrade from "../Common/Update";
import TableHeader from "./TableHeader";
import HoldingCard from "./Card/HoldingCard";
import VolumeCard from "./Card/VolumeCard";
import { NewTheme } from "../Theme/Theme";
import { Button } from "primereact/button";
import HoldingScriptModal from "./Modal/HoldingScriptModal";
import HoldingVolumeModal from "./Modal/HoldingVolumeModal";
import { Dropdown } from "primereact/dropdown";
import { connect } from "react-redux";

const php = new Php();
const height = window.innerHeight;
const totalHeight = (height / 100) * 95;

var filterTypeScript = "";
var filterDataScript = "";
var filterArrayScript = "";

var filterTypeSegment = "";
var filterDataSegment = "";
var filterArraySegment = "";

function Holding(props) {
  const [loading, setLoading] = useState(false);
  const ref = React.useRef();
  const { addNotification } = useNotification();

  const [buyVolume, setBuyVolume] = useState(0);
  const [sellVolume, setSellVolume] = useState(0);
  const [holding, setHolding] = useState([]);
  const [holdingFilter, setHoldingFilter] = useState([]);

  const [holdingScript, setHoldingScript] = useState([]);
  const [modelDataAmountSummary, SetModelDataAmountSummary] = useState("");
  const [symbol, setSymbol] = useState("");

  const [filterSymbol, setFilterSymbol] = React.useState([]);
  const [filterSymbol2, setFilterSymbol2] = React.useState([]);
  const [selectedSymbol, setSelectedSymbol] = React.useState({});

  const [filterSegment, setFilterSegment] = React.useState([
    { id: "0", segment: "FUTURE" },
    { id: "1", segment: "MCX" },
    { id: "2", segment: "OPTION" },
  ]);
  const [selectedSegment, setSelectedSegment] = React.useState({});

  React.useEffect(() => {
    load_holdings();
  }, []);

  const calcVolume = (holding) => {
    var totalBuyVolumeWithAvgGreaterThanZero = holding.reduce((total, item) => {
      if (parseFloat(item.buy_avg) > 0) {
        total += parseFloat(item.buy_volume);
      }
      return total;
    }, 0);
    setBuyVolume(totalBuyVolumeWithAvgGreaterThanZero);

    var totalSellVolumeWithAvgGreaterThanZero = holding.reduce(
      (total, item) => {
        if (parseFloat(item.sell_avg) > 0) {
          total += parseFloat(item.sell_volume);
        }
        return total;
      },
      0
    );
    setSellVolume(totalSellVolumeWithAvgGreaterThanZero);
  };

  const load_holdings = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        b_id: localStorage.getItem("brokersId"),
      };

      php.load_holdings(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          setHoldingFilter(r.holding);
          SetModelDataAmountSummary(r.segment_summary);

          const uniqueSymbols = new Set();
          const array = r.holding;

          array.forEach((item) => {
            const symbolKey = JSON.stringify({
              symbol: item.symbol,
              script_id: item.script_id,
              segment: item.segment,
            });

            uniqueSymbols.add(symbolKey);
          });

          // To get the unique objects back, you can convert them back from JSON
          const uniqueSymbolObjects = Array.from(uniqueSymbols).map((key) =>
            JSON.parse(key)
          );

          setFilterSymbol(Array.from(uniqueSymbolObjects));
          setFilterSymbol2(Array.from(uniqueSymbolObjects));

          if (filterTypeScript == "script") {
            filterScripts(filterDataScript, r.holding, filterArrayScript);
          } else {
            filterTypeScript = "";
            filterDataScript = "";
            filterArrayScript = "";

            filterTypeSegment = "";
            filterDataSegment = "";
            filterArraySegment = "";

            setHolding(r.holding);
            calcVolume(r.holding);
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const filterSegments = (a, data, selectedUserData) => {
    props.clear_pf();
    if (selectedUserData == undefined || selectedUserData == "") {
      filterTypeSegment = "";
      filterDataSegment = "";
      filterArraySegment = "";

      setSelectedSegment({});
      setFilterSymbol(filterSymbol2);
      setHolding(holdingFilter);
      calcVolume(holdingFilter);
    } else {
      filterTypeSegment = "";
      filterDataSegment = "";
      filterArraySegment = "";
      let positionData = [];
      console.log(a);
      positionData = data.filter((x) => x.segment == a);

      let newData = filterSymbol.filter((x) => x.segment == a);

      setFilterSymbol(newData);

      setHolding(positionData);
      setSelectedSegment(selectedUserData);
      calcVolume(positionData);

      filterTypeSegment = "segment";
      filterDataSegment = a;
      filterArraySegment = selectedUserData;
    }
  };

  const filterScripts = (a, data, selectedUserData) => {
    props.clear_pf();
    if (selectedUserData == undefined || selectedUserData == "") {
      filterTypeScript = "";
      filterDataScript = "";
      filterArrayScript = "";

      calcVolume(holdingFilter);

      if (filterTypeSegment == "segment") {
        filterSegments(filterDataSegment, holdingFilter, filterArraySegment);
      } else {
        setHolding(holdingFilter);
      }

      setSelectedSymbol({});
    } else {
      filterTypeScript = "";
      filterDataScript = "";
      filterArrayScript = "";

      let positionData = [];

      if (filterTypeScript == "segment") {
        positionData = data.filter(
          (x) => x.script_id == a && x.segment == filterDataSegment
        );
      } else {
        positionData = data.filter((x) => x.script_id == a);
      }

      setHolding(positionData);
      setSelectedSymbol(selectedUserData);
      calcVolume(positionData);

      filterTypeScript = "script";
      filterDataScript = a;
      filterArrayScript = selectedUserData;
    }
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 30,
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
        }}
      >
        <Dropdown
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 200,
            height: 30,
          }}
          showClear
          onChange={(e) => {
            if (e.value == undefined) {
              filterSegments(e.value, holding, e.value);
            } else {
              filterSegments(e.value?.id, holding, e.value);
            }
          }}
          value={selectedSegment}
          options={filterSegment}
          optionLabel="segment"
          placeholder="Filter Segment"
          filter
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
        }}
      >
        <Dropdown
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 200,
            height: 30,
          }}
          showClear
          onChange={(e) => {
            if (e.value == undefined) {
              filterScripts(e.value, holding, e.value);
            } else {
              filterScripts(e.value?.script_id, holding, e.value);
            }
          }}
          value={selectedSymbol}
          options={filterSymbol}
          optionLabel="symbol"
          placeholder="Filter Script"
          filter
        />
      </div>
      <VolumeCard buyVolume={buyVolume} sellVolume={sellVolume} ref={ref} />
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
        }}
      >
        <Button
          type="button"
          label="Volume"
          severity="help"
          style={{ height: 30 }}
          onClick={() => showTotalVolume()}
          data-pr-tooltip="PDF"
        />
      </div>
      {/* <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          icon="pi pi-file-pdf"
          severity="warning"
          style={{ height: 30, width: 30 }}
          onClick={() => window.alert("New Pdf Pending")}
          data-pr-tooltip="PDF"
        />
      </div> */}
    </div>
  );

  const [visible, setVisible] = useState(false);
  const [visibleTotalVolume, setVisibleTotalVolume] = useState(false);
  const [position, setPosition] = useState("top");

  const showTotalVolume = () => {
    setVisibleTotalVolume(true);
  };

  const load_holding_script = (script_id, symbol) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        b_id: localStorage.getItem("brokersId"),
        script_id: script_id,
      };

      php.load_holding_script(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setHoldingScript(r.holding_script);
          setSymbol(symbol);
          setVisible(true);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  return (
    <div className="card">
      <UpdateTrade update={() => load_holdings()} />
      <TopbarMost cmp={filterbar} name={"Holdings"} />

      {/* <TopbarMost noSidebar cmp={searchBar} /> */}
      <div
        className="scroll-css"
        style={{ overflowY: "scroll", height: totalHeight - 60 }}
      >
        <TableHeader />
        {holding.length > 0
          ? holding.map((i, t) => {
              return (
                <HoldingCard
                  item={i}
                  modelwork={(script_id, symbol) =>
                    load_holding_script(script_id, symbol)
                  }
                  // calcucalu={(id) => this.calculations(id)}
                  // sqoff_single={() => this.sqoff_single(i)}
                  key={i.id + i.buy_qty + new Date()}
                  total={holding.length}
                />
              );
            })
          : null}
      </div>
      {visible && (
        <HoldingScriptModal
          setVisible={() => setVisible(false)}
          position={position}
          visible={visible}
          symbol={symbol}
          holdingScript={holdingScript}
        />
      )}

      {visibleTotalVolume && (
        <HoldingVolumeModal
          setVisible={() => setVisibleTotalVolume(false)}
          position={position}
          visible={visibleTotalVolume}
          holdingScript={modelDataAmountSummary}
        />
      )}
    </div>
  );
}

const MapStateToDispatch = (dispatch) => {
  return {
    clear_pf: () => {
      dispatch({
        type: "CLEAR_PF3",
      });
    },
  };
};

export default connect(null, MapStateToDispatch)(Holding);
