import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";

import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment/moment";
import { FilterMatchMode } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import Php from "../../Backend/Php";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import { InputText } from "primereact/inputtext";
import NumberFormatIn from "../Common/NumberFormatIn";
const php = new Php();

const height = window.innerHeight;

export default function Brokerage() {
  const [transactionData, setTransactionData] = useState([]);
  const [transactionDataFilter, setTransactionDataFilter] = useState([]);

  const [usersFilter, setUsersFilter] = useState([]);
  const [brokerFilter, setBrokerFilter] = useState([]);

  const [totalBrokerage, setTotalBrokerage] = useState(0);

  const [searchValue, setSearchValue] = useState("");

  const [clientSelected, setClientSelected] = useState({});
  const [brokerSelected, setBrokerSelected] = useState({});

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const [date, setDate] = useState([new Date(), new Date()]);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  useEffect(() => {
    load_broker_client();
    load_brokerage();
  }, []);

  const load_broker_client = (a) => {
    if (!a) return;
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      b_id: localStorage.getItem("brokersId"),
    };

    php.load_broker_client_filter(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setUsersFilter(r.clients);
        setBrokerSelected(a);
      } else {
        alert(r.message);
      }
    });
  };

  const load_brokerage = async (clear) => {
    try {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        b_id: localStorage.getItem("brokersId"),
        c_id:
          clientSelected?.id == undefined || clear == "clear"
            ? 0
            : clientSelected?.id,
        from_date:
          clear == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[0]).format("YYYY-MM-DD 00:00:00"),
        to_date:
          clear == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[1]).format("YYYY-MM-DD 23:59:59"),
      };

      php.load_brokerage(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          setTransactionData(r.brokerages);
          setTotalBrokerage(r.total);
        } else {
          addNotification(r.message, "error");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const cols = [
    { dataKey: "username", title: "Username" },
    { dataKey: "symbol", title: "Symbol" },
    { dataKey: "brokerage", title: "Brokerage" },
    { dataKey: "broker_percent", title: "Broker %" },
    { dataKey: "broker_amt", title: "Amount" },
    { dataKey: "date_created", title: "Date" },
  ];

  const exportPdf = () => {
    // Your column and data definitions (replace these with your actual data)
    const doc = new jsPDF({
      orientation: "landscape",
    });

    doc.setFontSize(16);
    doc.text("Brokerage Report", 15, 15);

    const brokerageValue = 465456; // Replace with the actual calculation or value

    const brokerageColor = brokerageValue > 0 ? "#019c6b" : "#ff0000"; // Green if greater than 0, else red

    doc.setFontSize(12);
    doc.setTextColor("#000000"); // Set text color for the non-numeric part
    const summaryText = "Brokerage: ";
    doc.text(summaryText, 15, 25); // Adjust the coordinates as needed

    doc.setFont("helvetica", "bold"); // Set font style to bold
    doc.setFontSize(16);
    doc.setTextColor(brokerageColor); // Set text color for the numeric part
    doc.text(brokerageValue.toString(), 65, 25); // Adjust the coordinates as needed

    // Manually underline the numeric part
    const textWidth =
      (doc.getStringUnitWidth(brokerageValue.toString()) * 16) /
      doc.internal.scaleFactor; // Calculate width of the text
    const startX = 65; // X-coordinate of the numeric part
    const startY = 25 + 2; // Y-coordinate below the text
    doc.setLineWidth(0.5); // Set line width
    doc.setDrawColor(brokerageColor);
    doc.line(startX, startY, startX + textWidth, startY); // Draw the line

    // Reset font size, style, and text color
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal"); // Reset font style to normal
    doc.setLineWidth(0.2); // Reset line width
    doc.setDrawColor("#000000");
    doc.setTextColor("#000000");

    // Define styles for the table header
    const headerStyles = {
      fillColor: "#4a6ba1",
      textColor: "#ffffff",
    };

    // Add content to the PDF using autoTable with modified data and custom header styles
    const tableStartY = 35; // Adjust the startY value based on the header size and layout
    doc.autoTable(cols, transactionData, { startY: tableStartY, headerStyles });

    var pdfName =
      brokerSelected?.username != undefined
        ? brokerSelected?.username.toUpperCase() + " - Brokerage Report"
        : "(S)" +
          localStorage.getItem("server").toUpperCase() +
          " (M)" +
          localStorage.getItem("mastersUsername")?.toUpperCase() +
          " - Brokerage Report";

    doc.save(pdfName + ".pdf");
  };

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Total Brokerage</div>
        <div style={{ fontSize: 18, color: NewTheme.greencolor }}>
          {parseFloat(totalBrokerage).toFixed(2)}
        </div>
      </div>

      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
        }}
      >
        <InputText
          style={{ width: 140 }}
          type="search"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
      </div>

      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
        }}
      >
        <Dropdown
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 200,
          }}
          showClear
          onChange={({ target }) => {
            setClientSelected(target.value);
          }}
          value={clientSelected}
          options={usersFilter}
          optionLabel="username"
          placeholder="Filter Username"
          filter
        />
      </div>

      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Calendar
          style={{ width: 210 }}
          selectionMode="range"
          id="calendar-12h"
          value={date}
          onChange={(e) => setDate(e.value)}
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          // icon="pi pi-filter"
          severity="success"
          label="SUBMIT"
          style={{
            height: 25,
            // width: 30,
            fontSize: 12,
            padding: 10,
          }}
          // onClick={exportExcel}
          onClick={() => load_brokerage()}
          data-pr-tooltip="XLS"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          // icon="pi pi-times"
          label="CLEAR"
          severity="danger"
          style={{
            height: 25,
            // width: 30,
            fontSize: 12,
            padding: 10,
          }}
          onClick={() => {
            load_brokerage("clear");
          }}
          data-pr-tooltip="XLS"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="PDF"
          severity="warning"
          style={{
            height: 25,
            paddingLeft: 10,
            width: 80,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      </div>
    </div>
  );

  return (
    <div
      className="card"
      style={{
        // background: "black",
        // padding: "0.5rem",
        paddingTop: 0,
        // borderRadius: "10px",
        // marginBottom: "1rem",
        overflow: "hidden",
      }}
    >
      <TopbarMost name="Brokerages" cmp={filterbar} />

      <div style={{ position: "relative" }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 150}
          size="small"
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={transactionData}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
        >
          <Column
            field="broker_username"
            showFilterMenu={false}
            header="Broker"
            sortable
            filter
          />
          <Column
            field="username"
            showFilterMenu={false}
            header="Client"
            sortable
            filter
          />
          <Column
            filter
            field="symbol"
            showFilterMenu={false}
            header="Symbol"
          />
          <Column
            filter
            field="brokerage_amt"
            showFilterMenu={false}
            header="Brokerage"
            body={(rowData) => (
              <span>
                <NumberFormatIn value={rowData.brokerage_amt} />
              </span>
            )}
          />
          <Column
            field="broker_percent"
            showFilterMenu={false}
            filter
            header="Broker %"
          />
          <Column
            field="broker_amt"
            filter
            showFilterMenu={false}
            header="Amount"
            body={(rowData) => (
              <span>
                <NumberFormatIn value={rowData.broker_amt} />
              </span>
            )}
          />
          <Column
            filter
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Date/Time"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM ")}
                <br />
                <span style={{ color: NewTheme.MainColor }}>
                  {moment(rowData.date_created).format("HH:mm:ss")}
                </span>
              </div>
            )}
            sortable
          />
        </DataTable>
      </div>
    </div>
  );
}
